import logo from '../assets/images/logo.png';

const appBarMock = {
    logo,
    name: 'ROLANDO VC',
    menu: [
        { path: '/tours', description: 'TOURS & SERVICES'},
        { path: '/contact', description: 'CONTACT ME'}
    ],
    phone: '+152 (624) 155-1025',
    email: 'rolandovc963@gmail.com'
};

export default appBarMock;