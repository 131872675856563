import { useEffect, useState } from 'react';

import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import './slider.css';

import slider5 from '../../assets/images/slider/slider5.jpg';
import { SliderImages } from '../../interfaces';

const AutoplaySlider = withAutoplay(AwesomeSlider);

interface SliderProps {
    data: SliderImages[]; // Las imágenes del slider
}

const Slider: React.FC<SliderProps> = ({ data }) => {
    
    const [images, setImages] = useState<SliderImages[]>(data);  
    
    useEffect(() => {
      setImages([...data]);
    }, [data]);
    
    const sliderContent = images.length > 0 ? images : [{ id: 0, name: slider5 }];
  
    return (
        <AutoplaySlider
            key={images.map((img) => img.id).join('-')}
            play={true}
            cancelOnInteraction={false}
            bullets={false}
            interval={3000}
        >
            {sliderContent.map((item) => (
                <div key={item.id} data-src={item.name} />
            ))}
        </AutoplaySlider>
    );
  };

export default Slider;