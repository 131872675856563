import { SliderImages } from "../../interfaces";

export const sendMail = async (formData: any) =>{
    try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseURL}/page/sendmail-page`, {
            method: 'POST',
            body: formData,
        });
        
        const json = await response.json();

        return json;
    } catch (error) {
        console.error('Error sending email:', error);
    }
}

export const getSliderImages = async (): Promise<SliderImages[] | undefined> => {
    try {
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseURL}/page/slider-images`);
        
        const json: any = await response.json();
        const data = json.data.map((image: any) => {
            return {
                ...image,
                name: `${baseURL}/${image.name}`
            } as SliderImages;
        });

        return data;
    } catch (error) {
        console.error('Error getting sliders images:', error);
    }
}