import { useEffect, useState } from "react";

import Slider from "../../../shared/slider/slider";
import { getSliderImages } from "../../../api/services/api.services";
import { SliderImages } from "../../../interfaces";

const SliderHome = () => {
    const [images, setImages] = useState<SliderImages[]>([]);

    const fetchSliderImages = async (): Promise<SliderImages[]> => {
        try {
            const response = await getSliderImages();
            return response ?? [];
        } catch (error) {
            console.error('Error fetching slider images:', error);
            return [];
        }
    };

    useEffect(() => {
        const loadImages = async () => {
            const imageList = await fetchSliderImages();
            setImages(imageList);
        };
        loadImages();
    }, []);

    return (
      <div>
        <Slider data={images} />     
      </div>
    );
  };
  
  export default SliderHome;